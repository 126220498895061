import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_solutions.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="leading-relaxed text-lg">
  Trailguide is a lean and fast{" "}
  <a href="https://en.wikipedia.org/wiki/Progressive_web_application" target="wiki">
    web app
  </a>
  , often proven to be faster than native Android or iPhone apps. The platform is
  build on modern web technology, highly performant, scalable and is capable of serving
  content and maps to thousands of users simultaneously.
    </p>
    <br />
    <br />
    <h4>{`Speed`}</h4>
    <p>{`Trailguide is a `}<strong parentName="p">{`web app`}</strong>{`. No download, installation or registration process
is needed. A fully functional app with all available content shows up instantly
on your guests phones when
opening `}<a href="https://trailguide.net" target="tg">{`trailguide.net`}</a>{`.`}</p>
    <br />
    <p>{`Trailguide is very small and runs on any device with a web browser. From small
cheap phones, to Tesla cars. With only `}<strong parentName="p">{`270 KB`}</strong>{`, the app loads and starts in
less than a second in most cases. Even with a bad mobile reception, on
slower phones, or if the phones memory is full it will still work fine.`}</p>
    <br />
    <br />
    <Image src="solutions/highperformanceblack.png" className="my-12 w-1/2 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Scalability`}</h4>
    <p>{`The Trailguide app and database servers run
on `}<a href="https://aws.amazon.com/" target="wiki">{`Amazon Web Services`}</a>{`
and will scale automatically by starting new servers when needed and can handle
very high traffic loads.`}</p>
    <br />
    <p>{`Trailguide has developed its own topographical map based on the most recent
data from `}<a href="https://wiki.openstreetmap.org/wiki/Main_Page" target="osm">{`OpenStreetMap`}</a>{`.
The map is served from our own dedicated high speed server with huge amounts
of storage space so you know the map will always show up. We did not want to
depend on other services for this.`}</p>
    <Image src="solutions/server3.png" className="my-12 w-2/3 mx-auto" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      